<template>
  <div class="col-md-7 mx-auto">
    <div class="card shadow-lg rounded">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Empresa</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Nombre (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_nmbre">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Ciudad (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_ciudad">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Dirección (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_direccion">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Teléfono (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_telefono">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Logo:</label>
                <div class="col-md-8">
                  <img v-bind:src="logo" alt="Logo" style="border: 1px solid #E1E1DE">
                  <br>
                  <div class="custom-file col-md-8">
                    <input type="file" id="files" ref="files" v-on:change="addFiles" class="custom-file-input">
                    <label class="custom-file-label" for="files">Seleccionar archivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="text-center">
            <p class="msg">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true,
        item: {},
        message: '',
        ready: false,
        files: [],
        uploaded: false,
        logoChanged: false,
        logoSaved: false,
        logo: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      logo2(){
        if (this.logoChanged){
          if (this.logoSaved){
            return './../../repos/' +  String(this.item._id) + '/logos/' + this.item.cmp_logo;
          } else {
            return './../../repos/temp/logos/' + this.item.cmp_logo;
          }
          this.logoChanged = false;
        }
      }
    },
    created: function() {
      this.getItem();
    },
    methods: {
      saveItem(){
        var n = 0;
        for (var i = 0; i < this.files.length; i++){
          if (this.files[i].uploaded == '0'){
            n++;
          }
        }
        if (n < this.files.length){
          alert('Espere por favor, no se han cargado todos los archivos adjuntos.');
          return;
        }

        let uri = '/cmps/update/' + this.$store.state.company;
        this.axios.post(uri, this.item)
        .then((response) => {
          var cd = this.$store.state.companydata;
          var k = Object.keys(this.item);
          for (let i = 0; i < k.length; i++){
            cd[k[i]] = this.item[k[i]];
          }
          this.$store.commit('setCompanyData', cd);
          this.message = 'Información guardada.';
          var src = './../repos/temp/logos';
          var dst = './../repos/' + String(this.item._id) + '/logos';
          let uri2 = '/api/files/save';
          this.axios.post(uri2, { 'src': src, 'dst': dst, adjuntos: this.files })
          .then((response2) => {
            var path = './../repos/' + String(this.item._id) + '/logos';
            this.downloadFile(path, this.item.cmp_logo);
            this.message = 'Información y logo guardados.';
          }).catch((err2) => {
            this.message = 'Error al guardar la imagen del logo.';
          });
        })
        .catch((err) => {
            this.message = 'Error al guardar la información.';
        });
      
      },
      getItem()
      {
        let uri = '/cmps/edit/' + this.$store.state.company;
        this.axios.get(uri)
        .then((response) => {
          this.item = response.data;
          if (this.item.cmp_logo){
            this.files.push({});
            this.files[0].adj_nmbre = this.item.cmp_logo;
            this.files[0].temp = false;
            this.files[0].uploaded = '0';
            this.logoSaved = true;
            this.logoChanged = true;
            var path = './../repos/' + String(this.item._id) + '/logos';
            this.downloadNewLogo(path, this.item.cmp_logo);
          }
        });
      },
      addFiles: function(e) {
        var f = e.target.files;
        var data;
        var n;
        var m; //Indice de respuesta
        //var path = './src/public/images/logos/temp';
        var path = './../repos/temp/logos';

        if (f){
          this.ready = false;

          this.files = [];
          this.files.push({});
          this.files[0]['adj_nmbre'] = f[0].name;
          this.files[0]['uploaded'] = '1';
          this.files[0]['temp'] = true;
          this.$set(this.files, 0, this.files[0]);

          data = new FormData();
          // Append file
          data.append('file', f[0]);
          data.append('index', 0);
          data.append('path', path);

          //Make the request to the POST /multiple-files URL
          this.axios.post('/api/files/upload',
            data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
          )
          //.then(wait(5000))
          .then((response) => {
            let m = response.data.index;
            this.files[m]['uploaded'] = '0';
            this.$set(this.files, m, this.files[m]);
            this.item.cmp_logo = f[0].name;
            this.logoSaved = false;
            this.logoChanged = true;
            this.downloadNewLogo(path, this.item.cmp_logo);

            //console.log('SUCCESS!!');
          })
          .catch((err) => {
            let m = response.data.index;
            this.files[m]['uploaded'] = '2';
            this.$set(this.files, m, this.files[m]);
            //console.log('FAILURE!!');
          });
        }

        this.ready = true;
      },
      downloadFile(path, filename){
        this.axios({
          url: '/api/files/download',
          method: 'POST',
          data: { 'path': path, 'filename': filename },
          responseType: 'blob'
        }).then((response) => {
          this.$store.commit("setLogo", window.URL.createObjectURL(new Blob([response.data])));
        });
      },
      downloadNewLogo(path, filename){
        this.axios({
          url: '/api/files/download',
          method: 'POST',
          data: { 'path': path, 'filename': filename },
          responseType: 'blob'
        }).then((response) => {
          this.logo = window.URL.createObjectURL(new Blob([response.data]));
        });
      }
    }
  }
  </script>

  <style>
    .msg {
      color: #ff0000;
      background-color: white;
    }
  </style>
